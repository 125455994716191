import { ApplicationView } from "lib/mvc";
import SpinnerSvg from "assets/spinner.svg";
import { twMerge } from "tailwind-merge";

export const Spinner = ApplicationView(({ size, className }: { size: number; className?: string }) => {
  return (
    <SpinnerSvg
      className={twMerge("animate-spin fill-black", className)}
      style={{
        width: `${size}px`,
        height: `${size}px`,
      }}
    />
  );
});
