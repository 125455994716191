import { EventCallback, EventName } from "@tauri-apps/api/event";
import { Tauri, InvokePayload } from ".";

function stringifyForIpc(message: any) {
  return JSON.stringify(message, (_k, val) => {
    if (val instanceof Map) {
      let o = {};
      // @ts-ignore
      val.forEach((v, k) => (o[k] = v));
      return o;
    } else {
      return val;
    }
  });
}

export class MockTauri implements Tauri {
  socket?: WebSocket;
  client_id?: number;
  id: number;
  promises: Map<number, { resolve: (value: any) => void; reject: (reason?: any) => void }>;
  queue: InvokePayload[];
  callbacks: Map<number, EventCallback<any>>;
  eventIds: Map<string, number[]>;

  static setup() {
    // @ts-ignore
    window.__TAURI_POST_MESSAGE__ = (message: any) => {
      console.log("posting message", message);
      // @ts-ignore
      return window.ipc.postMessage(stringifyForIpc(message));
    };
  }

  _mockCommands = new Map<string, (args: Record<string, any>) => Promise<any>>();
  mockCommand(command: string, handler: (args: Record<string, any>) => Promise<any>) {
    this._mockCommands.set(command, handler);
  }
  clearMocks() {
    this._mockCommands.clear();
  }
  sendEvent(eventName: string, payload: any) {
    const ids = this.eventIds.get(eventName);
    if (!ids) return;

    for (let id of ids) {
      console.log("sending event", { id, payload });
      this.handleMessage({ data: JSON.stringify({ type: "event", id, payload }) });
    }
  }

  constructor() {
    this.id = 0;
    this.initializeWebSocket();

    this.promises = new Map<number, { resolve: (value: any) => void; reject: (reason?: any) => void }>();
    this.callbacks = new Map<number, EventCallback<unknown>>();
    this.eventIds = new Map();
    this.queue = [];
  }

  initializeWebSocket() {
    if (this.socket) return;

    try {
      this.client_id = undefined;
      this.socket = new WebSocket("ws://localhost:8000");
      this.socket.addEventListener("open", () => {
        this.dequeue();
      });
      this.socket.addEventListener("close", () => {
        this.backoffAndRetryWebSocket();
      });
      this.socket.addEventListener("error", (event) => {
        this.backoffAndRetryWebSocket();
      });
      this.socket.addEventListener("message", (event) => {
        this.handleMessage(event);
      });
    } catch (e) {
      console.error("Error initializing WebSocket", e);
      this.backoffAndRetryWebSocket();
    }
  }

  backoffAndRetryWebSocket() {
    this.socket = undefined;
    setTimeout(() => {
      this.initializeWebSocket();
    }, 1000);
  }

  get enabled() {
    return true;
  }

  generateId() {
    return Math.floor(Math.random() * Math.pow(2, 32));
  }

  handleMessage(event: { data: string }) {
    const data = JSON.parse(event.data);

    if (data.client_id) {
      console.log("setting client_id", data.client_id);
      this.client_id = data.client_id;
      this.dequeue();
      return;
    }

    if (data.type === "event") {
      console.log("handling event", data);
      const callback = this.callbacks.get(data.id);
      if (callback) {
        callback(data.payload);
      } else {
        console.error(`No callback found for event ${data.id}`);
      }
      return;
    }

    const promise = this.promises.get(data.id);
    if (promise) {
      if (data.error) {
        console.error(`Error invoking Tauri command: ${data}`);
        promise.resolve({ error: data.error });
        // if (data.error.error) promise.reject(new Error(data.error.error));
        // else promise.reject(new Error(data.error));
      } else {
        console.log(`Tauri command resolved: ${data.id}`, data.payload);
        promise.resolve(data.payload);
      }
      this.promises.delete(data.id);
    } else {
      console.error(`No promise found for id ${data.id}`);
    }
  }

  invoke<T>(command: string, args?: Record<string, any>): Promise<T> {
    if (!this.enabled) {
      throw new Error("Tauri is not enabled");
    }

    console.log(`Invoking Tauri command: ${command}`, args);

    if (this._mockCommands.has(command)) {
      return this._mockCommands.get(command)!(args || {});
    }

    const invoke_payload: InvokePayload = { cmd: command, payload: args || {}, id: this.generateId() };

    return new Promise((resolve, reject) => {
      this.promises.set(invoke_payload.id, { resolve, reject });
      this.queueAndDequeue(invoke_payload);
    });
  }

  emit(event: string, data: any) {}

  listen<Event>(event: EventName, callback: EventCallback<Event>): Promise<Function> {
    return new Promise((resolve, reject) => {
      let id = this.generateId();
      this.callbacks.set(id, callback);
      this.eventIds.set(event, [...(this.eventIds.get(event) || []), id]);
      this.promises.set(id, {
        resolve: () => {
          resolve(() => {
            this.callbacks.delete(id);
            this.promises.delete(id);
            this.queueAndDequeue({ cmd: "unlisten", payload: {}, id });
          });
        },
        reject,
      });
      this.queueAndDequeue({ cmd: "listen", payload: { event }, id });
    });
  }

  queueAndDequeue(payload: InvokePayload) {
    this.queue.push(payload);
    this.dequeue();
  }

  dequeue() {
    if (this.client_id && this.socket && this.socket.readyState === WebSocket.OPEN && this.queue.length > 0) {
      const payload = this.queue.shift();
      this.socket.send(JSON.stringify({ ...payload, client_id: this.client_id }));
      this.dequeue();
    }
  }

  async checkUpdate() {
    return { shouldUpdate: false };
  }
}
